import { Component, OnInit, Inject } from '@angular/core';
import { OutletHomeService } from '../../service-pool/outlet-home-service/outlet-home.service';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { LoginService } from '../../service-pool/login-service/login.service';
import { Router } from '@angular/router';
import { LogoffService } from '../../service-pool/business-services/logoff-service/logoff.service';
import { LoadDefaultDataService } from '../../service-pool/load-default-data-service/load-default-data.service';
import { CookieService } from 'ngx-cookie';
import { NotificationService } from '../../service-pool/business-services/notification-service/notification.service';
import { NotificationModeDTO } from '../../model-old/notification-mode-dto';
import { NotificationMessageDTO } from '../../model-old/notification-message-dto';
import { BackEndUrl } from '../../sharedutility/backend-url';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.css']
})
export class AgreementsComponent implements OnInit {
  loginDisclaimerViewed: boolean;
  regularEnvViewed: boolean;
  submitted: boolean;
  showRegularEnvLink: boolean;
  showLoginDisclaimerLink: boolean;
  errorMsg: string;
  brand: string;
  disclaimerDocUrl: string;
  regularotyDocUrl: string;
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private outletHomeService: OutletHomeService,
    private loginService: LoginService,
    private router: Router,
    private logoffService: LogoffService,
    private loadDefaultDataService: LoadDefaultDataService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    // this.brand = this.cookieService.get('bid');
    this.brand = 'VFS';
    this.disclaimerDocUrl = BackEndUrl.disclaimerDoc.split('${brand}').join(this.brand);
    this.regularotyDocUrl = BackEndUrl.regularotyDoc.split('${brand}').join(this.brand);
    this.loadDefaultDataService.getAgreementPage(this.brand).subscribe(data => {
      if (data && data.length === 1) {
        this.outletHomeService.setAgreementHeader(data[0] + '');
      }
    });
    this.showRegularEnvLink = this.outletHomeService.getShowViewedDisclaimer();
    this.showLoginDisclaimerLink = this.outletHomeService.getShowAgreementPage();
  }
  updateLoginDisclaimerViewedFlag() {
    this.loginDisclaimerViewed = true;
  }
  updateRegEnvViewedFlag() {
    this.regularEnvViewed = true;
  }
  submit() {
    this.submitted = true;
    if (this.loginDisclaimerViewed) {
      this.loginService.acceptAgreement('Yes', 'Yes').subscribe(data => {
        if (data['error'] === false) {
          this.outletHomeService.setShowAgreementPage(false);
          this.outletHomeService.setShowViewedDisclaimer(false);
          this.loginService.notifyLoggedIn();
          this.router.navigate(['home']).then(() => {
            window.location.reload();
          });
        } else {
          this.errorMsg = data['errors'][0]['errorMessage'];
        }
      });
    }
  }
  decline() {
    const regEnv = !this.showRegularEnvLink ? 'Yes' : 'No';
    const disclaimer = !this.showLoginDisclaimerLink ? 'Yes' : 'No';
    this.loginService.acceptAgreement(regEnv, disclaimer).subscribe(() => {
      this.logoffService.logOff();
    });
  }
}
