import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { CookieService } from 'ngx-cookie';
import { BrandingService } from 'src/app/service-pool/branding/branding.service';
import { CONFIG } from '../../model-old/config';
import { ChildMessageService } from '../../service-pool/child-message-service/child-message.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-sub-body',
  templateUrl: './sub-body.component.html',
  styleUrls: ['./sub-body.component.css'],
})
export class SubBodyComponent implements OnInit {
  @Input() subBody: string;

  @Input() underHome: boolean;

  @Output() childPathNavigation = new EventEmitter();

  config: object;

  childCanDeactive: boolean;

  brand: string;

  isCanadian: boolean = false;

  get vendorCode() {
    return this.brandService?.selectedProgram?.vendorcode || '';
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    protected _sanitizer: DomSanitizer,
    private childMessageService: ChildMessageService,
    private cookieService: CookieService,
    private brandService: BrandingService
  ) {
    this.config = CONFIG;
    this.childCanDeactive = false;
  }

  ngOnInit() {
    if (this.brandService?.programAttributes?.brandingTtype !== 'BMO') {
      this.brand = this.vendorCode;
    } else {
      this.brand = this.cookieService.get('bid');
    }
    this.childMessageService.canDeactiveChildApp().subscribe({
      next: (canDeactive) => {
        this.childCanDeactive = canDeactive;
      },
    });
  }

  safeHtml(html: string) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  navigate(path: string, child?: string) {
    const canNavigate = this.childMessageService.canNavigate(this.childCanDeactive, path, child);
    if (!canNavigate) {
      return false;
    }
    this.childPathNavigation.emit({ path, child });
    return false;
  }
}
