import { LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { L10nStorageService, LOCALE_BRAND_URL, LoadSpinnerService, UI_LABEL_PREFIX } from 'leasing-source-shared';
import { CookieModule } from 'ngx-cookie';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppComponent } from './app.component';
import { CustomUrlSerializer } from './class-serializer';
import { AgreementsComponent } from './component-pool/agreements/agreements.component';
import { ErrorPageComponent } from './component-pool/error-page/error-page.component';
import { FooterComponent } from './component-pool/footer/footer.component';
import { ForgetPwdComponent } from './component-pool/forget-pwd/forget-pwd.component';
import { ForgotPasswordComponent } from './component-pool/forgot-password/forgot-password.component';
import { HeaderComponent } from './component-pool/header/header.component';
import { HomeComponent } from './component-pool/home/home.component';
import { LoginComponent } from './component-pool/login/login.component';
import { NotFoundComponent } from './component-pool/not-found/not-found.component';
import { PlaceholderComponent } from './component-pool/placeholder/placeholder.component';
import { PopupModelComponent } from './component-pool/popup-model/popup-model.component';
import { CompanyRegistrationComponent } from './component-pool/register/company/company-registration.component';
import { RegisterComponent } from './component-pool/register/register.component';
import { UserRegistrationComponent } from './component-pool/register/user/user-registration.component';
import { ReloginComponent } from './component-pool/relogin/relogin.component';
import { SelectLanguageComponent } from './component-pool/select-language/select-language.component';
import { StaticPageComponent } from './component-pool/static-page/static-page.component';
import { SubBodyComponent } from './component-pool/sub-body/sub-body.component';
import { TopNavComponent } from './component-pool/top-nav/top-nav.component';
import { l10nConfig } from './l10n.config';
import { PortalRoutingModule } from './portal-routing.module';
import { ErrorInfoService } from './service-pool/business-services/error-info-service/error-info.service';
import { GuardService } from './service-pool/business-services/guard-service/guard.service';
import { LogSetupServiceService } from './service-pool/business-services/log-setup-service/log-setup-service.service';
import { LogoffService } from './service-pool/business-services/logoff-service/logoff.service';
import { NotificationService } from './service-pool/business-services/notification-service/notification.service';
import { RegistrationService } from './service-pool/business-services/registration-service/registration.service';
import { SpinningService } from './service-pool/business-services/spinning-service/spinning.service';
import { StatesService } from './service-pool/business-services/states-service/states.service';
import { ZipcodeService } from './service-pool/business-services/zipcode-service/zipcode.service';
import { ChildMessageService } from './service-pool/child-message-service/child-message.service';
import { CustomHashLocationStrategy } from './service-pool/custom-hash-location-strategy/custom-hash-location-strategy.service';
import { LogoffDataService } from './service-pool/data-services/logoff-service/logoff-data.service';
import { RegistrationDataService } from './service-pool/data-services/registration-service/registration-data.service';
import { StatesDataService } from './service-pool/data-services/states-service/states-data.service';
import { ZipcodeDataService } from './service-pool/data-services/zipcode-service/zipcode-data.service';
import { ForgetPasswordService } from './service-pool/forget-pwd-service/forget-pwd.service';
import { GetIdService } from './service-pool/get-id-service.service';
import { LanguageService } from './service-pool/language-service/language.service';
import { LoadDefaultDataService } from './service-pool/load-default-data-service/load-default-data.service';
import { OutletHomeService } from './service-pool/outlet-home-service/outlet-home.service';
import { TokenInfoService } from './service-pool/token-info-service/token-info.service';
import { BackEndUrl } from './sharedutility/backend-url';
import { FirstUserGuard } from './sharedutility/guard/first-user.guard';
import { LoginGuard } from './sharedutility/guard/login.guard';
import { SalesrepAccessGuard } from './sharedutility/guard/salesrep-access.guard';
import { XAuthTokenActiveGuard } from './sharedutility/guard/x-auth-token-active.guard';
import { HttpInterceptorProviders } from './sharedutility/http-interceptors/interceptors';
import { ClearNullPipe } from './sharedutility/pipe/clear-null/clear-null.pipe';
import { SafeHtmlPipe } from './sharedutility/pipe/safe-html.pipe';
import { SplitPipe } from './sharedutility/pipe/split.pipe';
import { Validations } from './sharedutility/validations';
import { BmoLoadingModule } from './utility/bmo-loading/bmo-loading.module';
import { BmoLoadingService } from './utility/bmo-loading/bmo-loading.service';

export function loggingConfigFactory(logSetupServiceService: LogSetupServiceService) {
  return () => logSetupServiceService.load();
}

export function getDateIdFactory(getIdService: GetIdService) {
  return () => getIdService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    FooterComponent,
    SubBodyComponent,
    HeaderComponent,
    HomeComponent,
    PlaceholderComponent,
    TopNavComponent,
    ErrorPageComponent,
    ReloginComponent,
    RegisterComponent,
    CompanyRegistrationComponent,
    UserRegistrationComponent,
    ForgetPwdComponent,
    StaticPageComponent,
    AgreementsComponent,
    PopupModelComponent,
    SplitPipe,
    SafeHtmlPipe,
    ClearNullPipe,
    SelectLanguageComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PortalRoutingModule,
    CookieModule.forRoot(),
    InputMaskModule,
    OverlayPanelModule,
    DialogModule,
    ButtonModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.OFF }),
    L10nTranslationModule.forRoot(l10nConfig, { storage: L10nStorageService }),
    L10nIntlModule,
    BmoLoadingModule,
  ],
  providers: [
    LogSetupServiceService,
    GetIdService,
    {
      provide: LoadSpinnerService,
      useClass: BmoLoadingService,
    },
    {
      provide: LOCALE_BRAND_URL,
      useValue: BackEndUrl.localeByBrand,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getDateIdFactory,
      deps: [GetIdService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loggingConfigFactory,
      deps: [LogSetupServiceService],
      multi: true,
    },
    LoadDefaultDataService,
    TokenInfoService,
    XAuthTokenActiveGuard,
    FirstUserGuard,
    ChildMessageService,
    HttpInterceptorProviders,
    OutletHomeService,
    SpinningService,
    Title,
    NotificationService,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: LocationStrategy, useClass: CustomHashLocationStrategy },
    {
      provide: UI_LABEL_PREFIX,
      useValue: 'PORTAL_LABEL',
    },
    LoginGuard,
    SalesrepAccessGuard,
    GuardService,
    LogoffDataService,
    LogoffService,
    Validations,
    ErrorInfoService,
    RegistrationDataService,
    RegistrationService,
    StatesDataService,
    StatesService,
    ForgetPasswordService,
    ZipcodeDataService,
    ZipcodeService,
    CookieService,
    LanguageService,
  ],
  schemas: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
