<br />
<div class="row-login" [ngStyle]="{ background: 'url(' + backgroundImageSrc + ') no-repeat' }">
  <div class="col-md-11"></div>
  <div *ngIf="visibleArray[0]" id="loginBox" class="col-md-1 login-box">
    <h2 class="text-center">{{ 'PORTAL_LABEL.LOGIN.LOGIN_TITLE' | translate: locale.language }}</h2>
    <!--<div #script1></div>-->
    <!--<div #script></div>-->
    <form name="login" autocomplete="off" [formGroup]="loginForm" (keyup.enter)="submitLogin()">
      <input type="hidden" name="login-form-type" value="pwd" />
      <div class="row form-group">
        <label for="username">{{ 'PORTAL_LABEL.USERNAME' | translate: locale.language }}</label>
        <input type="text" class="form-control" name="username" id="username" formControlName="username" />
        <span class="help-block" *ngIf="!loginForm.get('username').valid && loginForm.get('username').dirty">
          <span style="color: red" *ngIf="loginForm.get('username').errors['required']">
            {{ 'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED' | translate: locale.language }}
          </span>
        </span>
      </div>
      <div class="row form-group">
        <label for="password">{{ 'PORTAL_LABEL.PWD' | translate: locale.language }}</label>
        <input type="password" class="form-control" name="password" id="password" autocomplete="false" formControlName="password" />
        <span class="help-block" *ngIf="!loginForm.get('password').valid && loginForm.get('password').dirty">
          <span style="color: red" *ngIf="loginForm.get('password').errors['required']">
            {{ 'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED' | translate: locale.language }}
          </span>
        </span>
      </div>
      <div class="row form-group justify-content-center">
        <!--<a href="landing.htm">-->
        <!--<button type="button" class="btn-red">Login</button>-->
        <!--</a>-->
        <input
          type="button"
          name="SubmitLogin"
          value="{{ 'PORTAL_LABEL.LOGIN_BTN' | translate: locale.language }}"
          (click)="submitLogin()"
          class="btn-red"
          [disabled]="!loginForm.valid"
        />
      </div>
    </form>
    <div class="row justify-content-center">
      <span *ngIf="invalidUserPasswordLoginError" class="form-group" style="color: red">
        {{ 'PORTAL_LABEL.USER_PASSWORD_ERROR' | translate: locale.language }}
      </span>
      <span *ngIf="accountLockedLoginError" class="form-group" style="color: red">
        {{ 'PORTAL_LABEL.LOGIN_ATTEMPTS_ERROR' | translate: locale.language }}
      </span>
      <span *ngIf="failedLoginError" class="form-group" style="color: red">{{ 'PORTAL_LABEL.FAILED_LOGIN_ERROR' | translate: locale.language }}</span>
      <span *ngIf="genericLoginError" class="form-group" style="color: red">
        {{ 'PORTAL_LABEL.GENERIC_LOGIN_ERROR' | translate: locale.language }}
      </span>
      <span *ngIf="serverLoginError" class="form-group" style="color: red">
        {{ 'PORTAL_ERROR.ERR_MESG_SERVER_TIMEOUT' | translate: locale.language }}
      </span>
    </div>
    <!--    <p><a [routerLink]="['/forget']" [queryParams]="{brand: brand}">Forgot your Password?</a></p>-->
    <p *ngIf="showCanadian">
      <a id="selectLanguageLoginBox" href="javascript:void(0);" (click)="toggleLanguage()">
        {{ 'PORTAL_LABEL.SELECT_LANGUAGE_LINK' | translate: locale.language }}
      </a>
    </p>
    <p>
      {{ 'PORTAL_LABEL.LOGIN.NEW_USER' | translate: locale.language }}
      <a [routerLink]="['/register']" [queryParams]="{ brand: brand }">{{ 'PORTAL_LABEL.LOGIN.REGISTER' | translate: locale.language }}</a>
    </p>
    <p>
      <a href="javascript:void(0);" (click)="forgotPasswordFlow()">{{ 'PORTAL_LABEL.LOGIN.FORGOT_PASSWORD' | translate: locale.language }}</a>
    </p>
    <p>
      <a [href]="'#/help?brand=' + brand">{{ 'PORTAL_LABEL.LOGIN.HELP' | translate: locale.language }}</a>
    </p>
  </div>

  <div *ngIf="visibleArray[1]" id="otpDeliveryOptionsBox" class="col-md-1 login-box">
    <h2 class="text-center">{{ 'PORTAL_LABEL.LOGIN.VERIFY_TITLE' | translate: locale.language }}</h2>
    <p>
      {{ 'PORTAL_LABEL.LOGIN.VERIFY_DESCRIPTION' | translate: locale.language }}
      <i class="far fa-question-circle" (mouseenter)="op1.show($event)" style="padding-top: 5px" (mouseleave)="op1.hide()"></i>
    </p>
    <form name="otpDeliveryOptions" autocomplete="off" [formGroup]="otpDeliveryChoiceForm" (keyup.enter)="submitOTPDeliveryChoice()">
      <div class="row">
        <ul style="list-style-type: none; padding: 0; margin-bottom: 0">
          <li *ngFor="let target of deliveryTargetList">
            <input
              type="radio"
              class=""
              formControlName="otpOption"
              attr.data-label="{{ target['otp.user.otp-method.label'] }}"
              [value]="target['otp.user.otp-method.id']"
            />
            <span *ngIf="target['otp.user.otp-method.id'].toLowerCase().indexOf('sms') !== -1" class="convText">
              {{ 'PORTAL_LABEL.LOGIN.TEXT' | translate: locale.language }} - {{ target['otp.user.otp-method.label'] }}
            </span>
            <span *ngIf="target['otp.user.otp-method.id'].toLowerCase().indexOf('voice') !== -1" class="convText">
              {{ 'PORTAL_LABEL.LOGIN.CALL' | translate: locale.language }} - {{ target['otp.user.otp-method.label'] }}
            </span>
            <span
              *ngIf="
                target['otp.user.otp-method.id'].toLowerCase().indexOf('email') !== -1 &&
                target['otp.user.otp-method.id'].toLowerCase().indexOf('voice') === -1
              "
              class="convText"
            >
              {{ 'PORTAL_LABEL.LOGIN.EMAIL' | translate: locale.language }} - {{ target['otp.user.otp-method.label'] }}
            </span>
          </li>
        </ul>
      </div>
      <div class="row form-group">
        <span class="help-block" *ngIf="!otpDeliveryChoiceForm.get('otpOption').valid && otpDeliveryChoiceForm.get('otpOption').touched">
          <span style="color: red" *ngIf="otpDeliveryChoiceForm.get('otpOption').errors['required']">
            {{ 'PORTAL_ERROR.ERR_MESG_REQUIRED_OPTION' | translate: locale.language }}
          </span>
        </span>
      </div>
      <div class="row form-group justify-content-center">
        <input
          type="button"
          name="SubmitChoice"
          value="Send Code"
          (click)="submitOTPDeliveryChoice()"
          class="btn-red"
          [disabled]="!otpDeliveryChoiceForm.valid"
        />
      </div>
    </form>
    <div class="row justify-content-center">
      <span *ngIf="failedOTPChoiceError" class="form-group" style="color: red">
        {{ 'PORTAL_ERROR.ERR_MESG_OTP_OPTION' | translate: locale.language }}
      </span>
      <span *ngIf="genericOTPChoiceError" class="form-group" style="color: red">
        {{ 'PORTAL_ERROR.ERR_MESG_SERVER_TIMEOUT' | translate: locale.language }}
      </span>
    </div>
  </div>

  <div *ngIf="visibleArray[2]" id="otpCodeEntryBox" class="col-md-1 login-box">
    <h2 class="text-center">{{ 'PORTAL_LABEL.LOGIN.CODE_TITLE' | translate: locale.language }}</h2>
    <p>
      {{ 'PORTAL_LABEL.LOGIN.CODE_DESC1' | translate: locale.language }} {{ selectedTarget }}.
      {{ 'PORTAL_LABEL.LOGIN.CODE_DESC2' | translate: locale.language }}
    </p>
    <form name="otpCodeEntry" autocomplete="off" [formGroup]="otpCodeEntryForm" (keyup.enter)="submitOTPCodeEntry()">
      <div class="row form-group">
        <label for="code">{{ 'PORTAL_LABEL.LOGIN.ONE_TIME_CODE' | translate: locale.language }}</label>
        <input type="text" class="form-control" name="code" id="code" formControlName="code" />
        <span class="help-block" *ngIf="!otpCodeEntryForm.get('code').valid && otpCodeEntryForm.get('code').touched">
          <span style="color: red" *ngIf="otpCodeEntryForm.get('code').errors['required']">
            {{ 'PORTAL_ERROR.ERR_MESG_REQUIRED_CODE' | translate: locale.language }}
          </span>
        </span>
      </div>
      <div class="row form-group">
        <input type="checkbox" class="" name="rememberMe" id="rememberMe" formControlName="rememberMe" style="align-self: center" />
        <span class="convText" style="margin: 0; align-self: center">
          {{ 'PORTAL_LABEL.LOGIN.REMEMBER_DEVICE' | translate: locale.language }}
        </span>
        <span class="convText" style="margin: 0; align-self: center">
          {{ 'PORTAL_LABEL.LOGIN.REMEMBER_WARNING' | translate: locale.language }}
        </span>
      </div>
      <div>
        <p>{{ 'PORTAL_LABEL.LOGIN.CODE_INFO1' | translate: locale.language }}</p>
        <p>{{ 'PORTAL_LABEL.LOGIN.CODE_INFO2' | translate: locale.language }}</p>
        <ul style="padding-left: 15px">
          <li class="">
            <span>{{ 'PORTAL_LABEL.LOGIN.CODE_INFO3' | translate: locale.language }}</span>
          </li>
          <li class="">
            <span>{{ 'PORTAL_LABEL.LOGIN.CODE_INFO4' | translate: locale.language }}</span>
          </li>
        </ul>
      </div>
      <div class="row form-group justify-content-center">
        <input type="button" name="SubmitCode" value="Submit" (click)="submitOTPCodeEntry()" class="btn-red" [disabled]="!otpCodeEntryForm.valid" />
      </div>
    </form>
    <div class="row justify-content-center">
      <span *ngIf="failedOtpCodeEntryError" class="form-group" style="color: red">
        {{ 'PORTAL_ERROR.ERR_MESG_INVALID_OTP_CODE' | translate: locale.language }}
      </span>
      <span *ngIf="genericCodeEntryError" class="form-group" style="color: red">
        {{ 'PORTAL_ERROR.ERR_MESG_SERVER_TIMEOUT' | translate: locale.language }}
      </span>
    </div>
    <div class="text-center">
      <b>{{ 'PORTAL_LABEL.LOGIN.NO_CODE' | translate: locale.language }}</b>
      <span>
        <a id="resendCode" [routerLink]="[]" (click)="resendCode()">
          {{ 'PORTAL_LABEL.LOGIN.RESEND_CODE' | translate: locale.language }}
        </a>
      </span>
    </div>
  </div>

  <div *ngIf="visibleArray[3]" id="otpUpdatePasswordBox" class="col-md-1 login-box">
    <h2 class="text-center">{{ 'PORTAL_LABEL.LOGIN.UPDATE_PWD_TITLE' | translate: locale.language }}</h2>
    <p>{{ 'PORTAL_LABEL.LOGIN.UPDATE_PWD_DESC' | translate: locale.language }}</p>
    <form name="otpUpdatePassword" autocomplete="off" [formGroup]="otpUpdatePasswordForm" (keyup.enter)="submitUpdatePassword()">
      <div class="row form-group">
        <label for="newPassword">{{ 'PORTAL_LABEL.LOGIN.NEW_PWD' | translate: locale.language }}</label>
        <input type="password" class="form-control" name="newPassword" id="newPassword" autocomplete="false" formControlName="newPassword" />
        <span class="help-block" *ngIf="!otpUpdatePasswordForm.get('newPassword').valid && otpUpdatePasswordForm.get('newPassword').touched">
          <span style="color: red" *ngIf="otpUpdatePasswordForm.get('newPassword').errors['required']">
            {{ 'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED' | translate: locale.language }}
          </span>
          <span style="color: red" *ngIf="otpUpdatePasswordForm.get('newPassword').errors['pattern']">
            {{ 'PORTAL_ERROR.ERR_MESG_INVALID_NEW_PASSWORD' | translate: locale.language }}
          </span>
        </span>
      </div>
      <div class="row form-group">
        <label for="confirmPassword">{{ 'PORTAL_LABEL.LOGIN.COMFIRM_PWD' | translate: locale.language }}</label>
        <input
          type="password"
          class="form-control"
          name="confirmPassword"
          id="confirmPassword"
          autocomplete="false"
          formControlName="confirmNewPassword"
        />
        <span
          class="help-block"
          *ngIf="!otpUpdatePasswordForm.get('confirmNewPassword').valid && otpUpdatePasswordForm.get('confirmNewPassword').touched"
        >
          <span style="color: red" *ngIf="otpUpdatePasswordForm.get('confirmNewPassword').errors['required']">
            {{ 'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED' | translate: locale.language }}
          </span>
          <span style="color: red" *ngIf="otpUpdatePasswordForm.get('confirmNewPassword').errors['pattern']">
            {{ 'PORTAL_ERROR.ERR_MESG_INVALID_NEW_PASSWORD' | translate: locale.language }}
          </span>
        </span>
        <span class="help-block" *ngIf="otpUpdatePasswordForm.get('confirmNewPassword').touched">
          <span
            style="color: red"
            *ngIf="
              otpUpdatePasswordForm.get('newPassword').value !== otpUpdatePasswordForm.get('confirmNewPassword').value ||
              otpUpdatePasswordForm.hasError('mismatch')
            "
          >
            {{ 'PORTAL_ERROR.ERR_MESG_INVALID_CONFIRM_NEW_PASSWORD' | translate: locale.language }}
          </span>
        </span>
      </div>
      <div>
        <span class="convText" style="margin: 0; align-self: center">
          <p>{{ 'PORTAL_LABEL.LOGIN.PWD_NOTE' | translate: locale.language }}</p>
        </span>
      </div>
      <div class="row form-group justify-content-center">
        <input
          type="button"
          name="SubmitLogin"
          value="{{ 'PORTAL_LABEL.UPDATE_PASSWORD' | translate: locale.language }}"
          (click)="submitUpdatePassword()"
          class="btn-red"
          [disabled]="!otpUpdatePasswordForm.valid"
        />
      </div>
    </form>
    <div class="row justify-content-center">
      <span *ngIf="failedUpdatePasswordError" class="form-group" style="color: red">
        {{ 'PORTAL_ERROR.ERR_MESG_INVALID_UPDATE_PASSWORD' | translate: locale.language }}
      </span>
      <span *ngIf="failedOldPasswordError" class="form-group" style="color: red">
        {{ 'PORTAL_ERROR.ERR_MESG_INVALID_OLD_PASSWORD' | translate: locale.language }}
      </span>
      <span *ngIf="genericUpdatePasswordError" class="form-group" style="color: red">
        {{ 'PORTAL_ERROR.ERR_MESG_SERVER_TIMEOUT' | translate: locale.language }}
      </span>
    </div>
  </div>

  <div *ngIf="visibleArray[4]" id="forgotPasswordBox" class="col-md-1 login-box">
    <portal-forgot-password (returnToLoginFlow)="handleReturnToLoginFlow($event)"></portal-forgot-password>
  </div>
</div>

<p-overlayPanel #op1 [dismissable]="true" [style]="{ width: '250px' }">
  <span class="">
    <h3>{{ 'PORTAL_LABEL.LOGIN.NOTICE' | translate: locale.language }}:</h3>
    <p class="font-weight-normal">{{ 'PORTAL_LABEL.LOGIN.VERIFY_INFO1' | translate: locale.language }}</p>
    <p class="font-weight-normal">{{ 'PORTAL_LABEL.LOGIN.VERIFY_INFO2' | translate: locale.language }}</p>
    <p class="font-weight-normal">{{ 'PORTAL_LABEL.LOGIN.VERIFY_INFO3' | translate: locale.language }}</p>
  </span>
</p-overlayPanel>
<!--<FORM ACTION="/pkmslogin.form" METHOD="POST" onSubmit="getTarget()" NAME="LOGIN" ENCTYPE="application/x-www-form-urlencoded" AUTOCOMPLETE = "off">-->
<!--<label for="username">Username: </label>-->
<!--<input type="TEXT" name="username" id="username">-->
<!--<label for="password">Password: </label>-->
<!--<input type="PASSWORD" name="password" id="password" value autocomplete="false">-->
<!--<input type="submit" name="Submit" value="Login">-->
<!--</FORM>-->
