import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegistrationDTO } from '../../../model-old/registration-dto';
import { BackEndUrl } from '../../../sharedutility/backend-url';
import { Observable } from 'rxjs';

@Injectable()
export class RegistrationDataService {

  constructor(private httpClient: HttpClient) { }

  register(registrationDTO: RegistrationDTO): Observable<any> {
    return this.httpClient.post(BackEndUrl.register, registrationDTO);
  }

}
