import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadSpinnerService } from 'leasing-source-shared';
import { finalize, Observable } from 'rxjs';

@Injectable()
export class StartStopInterceptor implements HttpInterceptor {
  constructor(private spinner: LoadSpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.start();
    return next.handle(request).pipe(
      finalize(() => {
        this.spinner.stop();
      })
    );
  }
}
