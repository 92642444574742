import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { Company_Title } from 'src/app/model-old/company-title';
import { DealerProgram } from 'src/app/model/dealer-program.dto';
import { BrandingService } from 'src/app/service-pool/branding/branding.service';
import { SubSink } from 'subsink';
import { CONFIG } from '../../model-old/config';
import { ChildMessageService } from '../../service-pool/child-message-service/child-message.service';
import { OutletHomeService } from '../../service-pool/outlet-home-service/outlet-home.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css'],
})
export class TopNavComponent implements OnInit, OnDestroy {
  @Output() childPathNavigation = new EventEmitter();

  @Input() hideSubNav: Subject<void>;

  dealerPrograms: DealerProgram[] = this.brandService.dealerPrograms;

  selectedProgram: DealerProgram = this.brandService.selectedProgram;

  sub = new SubSink();

  config = CONFIG;

  childCanDeactive: boolean = false;

  companyName: string = 'Placeholder for backend data';

  brand: string;

  visibleArray: boolean[] = [false, false, false];

  showCanadian: boolean = false;

  isCLAAS = false;

  isTakeuchi = false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private childMessageService: ChildMessageService,
    private cookieService: CookieService,
    private outletHomeService: OutletHomeService,
    private brandService: BrandingService
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.sub.sink = this.hideSubNav.subscribe(() => {
      this.hideAllSubMenu();
    });
    this.brand = this.cookieService.get('bid');
    this.showCanadian = this.brand?.toUpperCase()?.includes('CA');

    this.childMessageService.canDeactiveChildApp().subscribe((canDeactive) => {
      this.childCanDeactive = canDeactive;
    });
    this.outletHomeService.getCompanyName().subscribe((companyName) => {
      this.companyName = companyName;
    });
    this.brand = this.cookieService.get('bid');
    this.isCLAAS =
      this.brandService?.programAttributes?.brandingTtype === 'BMO' ||
      Company_Title?.[this.brandService?.selectedProgram?.vendorcode] === 'CFS' ||
      Company_Title?.[this.brandService?.selectedProgram?.vendorcode] === 'BMO';
    this.isTakeuchi = Company_Title?.[this.brandService?.selectedProgram?.vendorcode] === 'TFS';
  }

  navigate(path: string, child?: string, refresh?: boolean) {
    this.hideAllSubMenu();
    const canNavigate = this.childMessageService.canNavigate(this.childCanDeactive, path, child);
    if (!canNavigate) {
      return false;
    }
    this.childPathNavigation.emit({ path, child, refresh });
    return false;
  }

  showHideSubmenu(menuIndex: number) {
    this.visibleArray.forEach((v, index) => {
      if (menuIndex !== index) {
        this.visibleArray[index] = false;
      }
    });
    this.visibleArray[menuIndex] = !this.visibleArray[menuIndex];
  }

  hideSubMenu(menuIndex) {
    this.visibleArray[menuIndex] = false;
  }

  hideAllSubMenu() {
    this.visibleArray.fill(false);
  }

  programChanged(p: DealerProgram) {
    if (p.vendorcode !== this.selectedProgram.vendorcode) {
      this.cookieService.putObject('pid', { ...p, vendorLocation: this.brandService.vendorLocationNumber });
      this.navigate('#/home', null, true);
    }
  }
}
