import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgreementsComponent } from './component-pool/agreements/agreements.component';
import { ErrorPageComponent } from './component-pool/error-page/error-page.component';
import { HomeComponent } from './component-pool/home/home.component';
import { LoginComponent } from './component-pool/login/login.component';
import { NotFoundComponent } from './component-pool/not-found/not-found.component';
import { PlaceholderComponent } from './component-pool/placeholder/placeholder.component';
import { RegisterComponent } from './component-pool/register/register.component';
import { StaticPageComponent } from './component-pool/static-page/static-page.component';
import { FirstUserGuard } from './sharedutility/guard/first-user.guard';
import { LoginGuard } from './sharedutility/guard/login.guard';
import { SalesrepAccessGuard } from './sharedutility/guard/salesrep-access.guard';

const portalAppRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [LoginGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'help',
    component: StaticPageComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'contact',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'productsServices',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'terms',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'secure',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'communication',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'fmv',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'lease-loan-diff',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'leasing-benefit',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'leasing-glossary',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'loans',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'lease',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'fmv-leases',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'how-to-sell',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'questions',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'troubleshoot',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'advantages',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'doc-lib',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'doc-lib/sample',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'trac-lease',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'training-lib',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'uniq-structure',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'quasi-lease',
    component: StaticPageComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'ca',
    component: PlaceholderComponent,
    canActivate: [FirstUserGuard],
    canActivateChild: [FirstUserGuard],
    children: [
      {
        path: '**',
        component: PlaceholderComponent,
      },
    ],
  },
  {
    path: 'dmc',
    component: PlaceholderComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'takedown',
    component: PlaceholderComponent,
    canActivate: [FirstUserGuard],
    canActivateChild: [FirstUserGuard],
    children: [
      {
        path: '**',
        component: PlaceholderComponent,
      },
    ],
  },
  {
    path: 'esign',
    component: PlaceholderComponent,
    canActivate: [FirstUserGuard],
    canActivateChild: [FirstUserGuard],
    children: [
      {
        path: '**',
        component: PlaceholderComponent,
      },
    ],
  },
  {
    path: 'esign-documents',
    component: PlaceholderComponent,
    canActivate: [FirstUserGuard],
  },
  {
    path: 'reports',
    component: PlaceholderComponent,
    canActivate: [FirstUserGuard],
    children: [
      {
        path: '',
        canActivateChild: [FirstUserGuard],
        children: [
          { path: 'DMC_Funding/:id', component: PlaceholderComponent },
          { path: 'DMC_Funding_Proceeds/:id', component: PlaceholderComponent },
        ],
      },
    ],
  },
  {
    path: 'salesrep',
    component: PlaceholderComponent,
    canActivate: [SalesrepAccessGuard],
    children: [
      {
        path: '',
        canActivateChild: [SalesrepAccessGuard],
        children: [
          { path: 'emulate-dealer', component: PlaceholderComponent },
          { path: 'vvloc', component: PlaceholderComponent },
          { path: 'profile', component: PlaceholderComponent },
          { path: 'takedown', component: PlaceholderComponent },
          { path: 'setup-vvloc', component: PlaceholderComponent },
          { path: 'setup-payproceed-payee', component: PlaceholderComponent },
          { path: 'idealease-dlf', component: PlaceholderComponent },
        ],
      },
    ],
  },
  {
    path: 'salesrep/secure',
    component: StaticPageComponent,
    canActivate: [SalesrepAccessGuard],
  },
  {
    path: 'salesrep/terms',
    component: StaticPageComponent,
    canActivate: [SalesrepAccessGuard],
  },
  {
    path: 'admin',
    canActivate: [FirstUserGuard],
    component: PlaceholderComponent,
    children: [
      {
        path: '',
        canActivateChild: [FirstUserGuard],
        children: [
          { path: 'add-user', component: PlaceholderComponent },
          { path: 'edit-user', component: PlaceholderComponent },
          { path: 'select-edit-user', component: PlaceholderComponent },
          { path: 'delete-user', component: PlaceholderComponent },
          { path: 'enable-disable-user', component: PlaceholderComponent },
          { path: 'additional-notifications', component: PlaceholderComponent },
          { path: 'user-profile', component: PlaceholderComponent },
          { path: 'user-profile-report', component: PlaceholderComponent },
        ],
      },
    ],
  },
  {
    path: 'inter-admin',
    component: PlaceholderComponent,
    canActivate: [SalesrepAccessGuard],
    children: [
      {
        path: '',
        canActivateChild: [SalesrepAccessGuard],
        children: [
          { path: 'configure-company', component: PlaceholderComponent },
          { path: 'delete-company', component: PlaceholderComponent },
          { path: 'add-salesreps', component: PlaceholderComponent },
          { path: 'select-salesreps', component: PlaceholderComponent },
          { path: 'edit-salesrep', component: PlaceholderComponent },
          { path: 'delete-salesreps', component: PlaceholderComponent },
          { path: 'add-brand', component: PlaceholderComponent },
          { path: 'select-brands', component: PlaceholderComponent },
          { path: 'edit-brand', component: PlaceholderComponent },
        ],
      },
    ],
  },
  {
    path: 'profile',
    canActivate: [FirstUserGuard],
    component: PlaceholderComponent,
  },
  {
    path: 'pe',
    canActivate: [FirstUserGuard],
    component: PlaceholderComponent,
    canActivateChild: [FirstUserGuard],
    children: [
      {
        path: '**',
        component: PlaceholderComponent,
      },
    ],
  },
  {
    path: 'agreements',
    canActivate: [FirstUserGuard],
    component: AgreementsComponent,
  },
  {
    path: 'home',
    canActivate: [FirstUserGuard],
    component: HomeComponent,
  },
  { path: 'error', component: ErrorPageComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: 'not-found' },
];
@NgModule({
  imports: [CommonModule, RouterModule.forRoot(portalAppRoutes, { enableTracing: false, useHash: true })],
  exports: [RouterModule],
  declarations: [],
})
export class PortalRoutingModule {}
