import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { catchError, Observable, of } from 'rxjs';
import { LoadDefaultDataService } from '../../load-default-data-service/load-default-data.service';
import { LoginService } from '../../login-service/login.service';

@Injectable({
  providedIn: 'root',
})
export class StaticPageService {
  private loggedIn: boolean;

  constructor(
    private loadDefaultDataService: LoadDefaultDataService,
    private cookieService: CookieService,
    private loginService: LoginService
  ) {
    this.loginService.getLoggedIn().subscribe({
      next: (logStatus) => {
        this.loggedIn = logStatus;
      },
    });
  }

  getStaticHtml(url: string): Observable<string> {
    return this.getStaticHtmlImpl(url).pipe(
      catchError((error) => {
        console.log(error);
        return of('No content on file');
      })
    );
  }

  getStaticHtmlImpl(url: string): Observable<string> {
    const brand = this.getParam('brand') ? this.getParam('brand') : this.cookieService.get('bid');
    const assetType = this.loggedIn ? 'secure' : 'public';
    if (url.indexOf('terms') !== -1) {
      return this.loadDefaultDataService.getTerms(brand);
    }
    if (url.indexOf('secure') !== -1) {
      return this.loadDefaultDataService.getSecurity(brand);
    }
    if (url.startsWith('productsServices')) {
      return this.loadDefaultDataService.getProductAndService(brand);
    }
    if (url.startsWith('contact')) {
      const apiCall =
        assetType === 'public' ? this.loadDefaultDataService.getContactUsPublic(brand) : this.loadDefaultDataService.getContactUs(brand);
      return apiCall;
    }
    if (url.startsWith('help')) {
      return this.loadDefaultDataService.getHelpPage(brand);
    }
    if (url.startsWith('advantages')) {
      return this.loadDefaultDataService.getAdvantages(brand);
    }
    switch (url) {
      case 'communication':
        return this.loadDefaultDataService.getCommunication(brand);
      case 'fmv':
        return this.loadDefaultDataService.getFMV(brand);
      case 'lease-loan-diff':
        return this.loadDefaultDataService.getDiffLeaseAndLoan(brand);
      case 'leasing-benefit':
        return this.loadDefaultDataService.getBenefitOfLeasing(brand);
      case 'leasing-glossary':
        return this.loadDefaultDataService.getLeasingGlossary(brand);
      case 'training-lib':
        return this.loadDefaultDataService.getTrainingLib(brand);
      case 'loans':
        return this.loadDefaultDataService.getLoans(brand);
      case 'lease':
        return this.loadDefaultDataService.getLease(brand);
      case 'fmv-leases':
        return this.loadDefaultDataService.getFMVLease(brand);
      case 'how-to-sell':
        return this.loadDefaultDataService.getHowSellLeasing(brand);
      case 'questions':
        return this.loadDefaultDataService.getQuestions(brand);
      case 'troubleshoot':
        return this.loadDefaultDataService.getTroubleshoot(brand);
      case 'doc-lib/sample':
        return this.loadDefaultDataService.getSampleDocLib(brand);
      case 'doc-lib':
        return this.loadDefaultDataService.getDocLib(brand);
      case 'trac-lease':
        return this.loadDefaultDataService.getTracLease(brand);
      case 'uniq-structure':
        return this.loadDefaultDataService.getUniqueStructure(brand);
      case 'quasi-lease':
        return this.loadDefaultDataService.getQuasiLease(brand);
      default:
        return of('');
    }
  }

  private getParam(name: string): string | null {
    const results = new RegExp(`[\\?&]${name}=([^&#]*)`).exec(window.location.href);
    if (!results) {
      return null;
    }
    return results[1] || null;
  }
}
