<div id="portalTop"></div>
<div [hidden]="leaveSalesrepFlag" *ngIf="loaded" [ngClass]="{ 'overlay-div': errorStatus === 401 }">
  <app-header
    [header]="publicHeader"
    [internalPage]="internalPage"
    (childPathNavigation)="handleChildPath($event)"
    [selectedLangAbbrv]="selectedLanguage"
  ></app-header>
  <div class="container-fluid">
    <div *ngIf="loggedIn && !internalPage">
      <app-top-nav #topNav [hideSubNav]="hideSubNav" (childPathNavigation)="handleChildPath($event)"></app-top-nav>
    </div>
    <div>
      <portal-select-language
        #languageSelect
        [showSelect]="showLanguageSelect"
        (selectedLanguage)="handleSelectedLanguage($event)"
      ></portal-select-language>
    </div>
    <div id="child">
      <router-outlet></router-outlet>
      <div id="ca-ui"></div>
      <div id="dmc-ui"></div>
      <div id="reports"></div>
      <div id="admin-ui"></div>
      <div id="salesrep-ui"></div>
      <div id="pe-ui"></div>
    </div>
    <div *ngIf="underHome || externalLandingPage">
      <app-sub-body [subBody]="subBody" [underHome]="underHome" (childPathNavigation)="handleChildPath($event)"></app-sub-body>
    </div>
  </div>
  <app-footer [internalPage]="internalPage" [unprotectedPage]="unprotectedPage"></app-footer>
  <input style="display: none" id="sid" [attr.value]="uuid" />
  <input style="display: none" id="sUserName" [attr.value]="userName" />
  <input style="display: none" id="olLoggedUserId" [attr.value]="userName" />
  <input style="display: none" id="olLoggedUserEmail" [attr.value]="userEmail" />
  <input style="display: none" id="olDealerName" [attr.value]="dealerName" />
  <input style="display: none" id="olDealerPhone" [attr.value]="dealerPhone" />
</div>
<app-popup-model [notificationMessage]="notificationMessage" [display]="display" *ngIf="display | async"></app-popup-model>
