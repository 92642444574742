import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationMessageDTO } from '../../../model-old/notification-message-dto';

@Injectable()
export class NotificationService {
  private _notification: Subject<Object>;

  constructor() {
    this._notification = new Subject<Object>();
  }

  notify(message: NotificationMessageDTO) {
    this._notification.next(message);
  }
  getNotification() {
    return this._notification.asObservable();
  }
}
