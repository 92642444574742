import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResponse } from 'leasing-source-shared';
import { Observable, map, tap } from 'rxjs';
import { DealerProgramsResponse } from 'src/app/model/dealer-program-response.dto';
import { DealerProgram } from 'src/app/model/dealer-program.dto';
import { ProgramAttributes } from 'src/app/model/program-attributes.dto';
import { BackEndUrl } from 'src/app/sharedutility/backend-url';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  private _dealerPrograms: DealerProgram[] = [];

  private _selectedProgram: DealerProgram = null;

  private _programAttributes: ProgramAttributes = null;

  private _vendorLocationNumber: string = '';

  constructor(private httpClient: HttpClient) {}

  get dealerPrograms(): DealerProgram[] {
    return JSON.parse(JSON.stringify(this._dealerPrograms));
  }

  get programAttributes(): ProgramAttributes {
    return JSON.parse(JSON.stringify(this._programAttributes));
  }

  get selectedProgram(): DealerProgram {
    return JSON.parse(JSON.stringify(this._selectedProgram));
  }

  get vendorLocationNumber(): string {
    return this._vendorLocationNumber;
  }

  getDealerPrograms(dealerNumber: string): Observable<DealerProgram[]> {
    this._vendorLocationNumber = dealerNumber;
    return this.httpClient.get<ServiceResponse<DealerProgramsResponse>>(BackEndUrl.dealerPrograms + dealerNumber).pipe(
      map((response) => {
        if (response.result) {
          return response.result.dealerPrograms;
        }
        throw new Error(`${response.errors[0].errorCode} ${response.errors[0].errorMessage}`);
      }),
      tap((result) => {
        this._dealerPrograms = result;
      })
    );
  }

  getBrmsProgramAttributes(dp: DealerProgram): Observable<ProgramAttributes> {
    this._selectedProgram = dp;
    return this.httpClient.get<ServiceResponse<ProgramAttributes>>(BackEndUrl.brmsProgramAttributes + dp.vendorcode).pipe(
      map((response) => {
        if (response.result) {
          return response.result;
        }
        throw new Error(`${response.errors[0].errorCode} ${response.errors[0].errorMessage}`);
      }),
      tap((result) => {
        this._programAttributes = result;
      })
    );
  }
}
