/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/prefer-default-export */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { ServiceResponse } from 'leasing-source-shared';
import { forkJoin, map, Observable, switchMap } from 'rxjs';
import { CompanyDetails } from 'src/app/model/company-details.dto';
import { BackEndUrl } from '../../sharedutility/backend-url';
import { BrandingTypes } from '../branding/branding-types.enum';
import { BrandingService } from '../branding/branding.service';
import { LocaleStoreService } from '../data-services/locale-store/locale-store.service';
import { GetIdService } from '../get-id-service.service';

@Injectable({
  providedIn: 'root',
})
export class LoadDefaultDataService {
  constructor(
    private httpClient: HttpClient,
    private translation: L10nTranslationService,
    private localeStore: LocaleStoreService,
    private dateTimeId: GetIdService,
    private brandService: BrandingService
  ) {}

  getUnprotectedPage(brand: string) {
    const csrf = this.httpClient.get(BackEndUrl.csrf);
    const phoneNo = this.httpClient.get(BackEndUrl.customerSupportPhoneNO + brand);

    return forkJoin([...this.getUnprotectedPageHTML(brand), csrf, phoneNo]);
  }

  getUnprotectedPageHTML(brand: string): Observable<any>[] {
    const publicHeader = this.appendLanguageMarker(`${BackEndUrl.publicHeader}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        return this.httpClient.get(this.generatePrivateLabelURL(brand, url), { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    const publicSubBody = this.appendLanguageMarker(`${BackEndUrl.publicSubBody}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        return this.httpClient.get(this.generatePrivateLabelURL(brand, url), { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );

    return [publicHeader, publicSubBody];
  }

  getSalesrepHeader() {
    return this.httpClient.get(`${BackEndUrl.internalLadningHeader}?${this.dateTimeId.getDateTimeId}`, { responseType: 'text' });
  }

  getSalesrepPage() {
    const allowedSections = this.httpClient.get<ServiceResponse<string[]>>(BackEndUrl.sectionAllowed);
    const salesrepHeader = this.httpClient.get(`${BackEndUrl.internalLadningHeader}?${this.dateTimeId.getDateTimeId}`, { responseType: 'text' });
    return forkJoin([salesrepHeader, allowedSections]);
  }

  getSalesRepPermission() {
    return this.httpClient.get(BackEndUrl.userDetails);
  }

  getTokens(): Observable<boolean> {
    const csrf = this.httpClient.get<ServiceResponse<boolean>>(BackEndUrl.csrf).pipe(
      map((response) => {
        if (response.result) {
          return response.result;
        }
        throw new Error(`${response.errors[0].errorCode} ${response.errors[0].errorMessage}`);
      })
    );
    const jwt = this.httpClient.get<ServiceResponse<boolean>>(BackEndUrl.jwtToken).pipe(
      map((response) => {
        if (response.result) {
          return response.result;
        }
        throw new Error(`${response.errors[0].errorCode} ${response.errors[0].errorMessage}`);
      })
    );
    return forkJoin([csrf, jwt]).pipe(
      map(([csrfResponse, jwtResponse]) => {
        return csrfResponse && jwtResponse;
      })
    );
  }

  getAllowedSections() {
    return this.httpClient.get<ServiceResponse<string[]>>(BackEndUrl.sectionAllowed).pipe(
      map((response) => {
        if (response.result) {
          return response.result;
        }
        throw new Error(`${response.errors[0].errorCode} ${response.errors[0].errorMessage}`);
      })
    );
  }

  getUserDetails() {
    return this.httpClient.get<ServiceResponse<any>>(BackEndUrl.userDetails).pipe(
      map((response) => {
        if (response.result) {
          return response.result;
        }
        throw new Error(`${response.errors[0].errorCode} ${response.errors[0].errorMessage}`);
      })
    );
  }

  getProtectedPage(brand: string) {
    const allowedSections = this.httpClient.get(BackEndUrl.sectionAllowed);
    const userDetails = this.httpClient.get(BackEndUrl.userDetails);

    return forkJoin([...this.getProtectedPageHTML(brand), allowedSections, userDetails]);
  }

  getProtectedPageHTML(brand: string): Observable<any>[] {
    const privateSubBody = this.appendLanguageMarker(`${BackEndUrl.privateSubBody}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    const privateHome = this.appendLanguageMarker(`${BackEndUrl.privateHome}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    return [privateSubBody, privateHome];
  }

  getAgreementPage(brand: string) {
    const agreementHeader = this.appendLanguageMarker(`${BackEndUrl.firstUserHeader}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    return forkJoin([agreementHeader]);
  }

  getCompanyName(dealerId: string | number) {
    return this.httpClient.get<ServiceResponse<CompanyDetails>>(BackEndUrl.companyName + dealerId);
  }

  getCompanyNameAndDetails(dealerId: string | number) {
    return this.httpClient.get<ServiceResponse<CompanyDetails>>(BackEndUrl.companyName + dealerId).pipe(
      map((response) => {
        if (response.result) {
          return response.result;
        }
        throw new Error(`${response.errors[0].errorCode} ${response.errors[0].errorMessage}`);
      })
    );
  }

  getProductAndService(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.productsService}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getContactUs(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.contactUs}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getContactUsPublic(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.contactUsPublic}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        return this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getTerms(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.terms}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getSecurity(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.secure}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getCommunication(brand: string): Observable<any> {
    const fullhtml = this.appendLanguageMarker(`${BackEndUrl.communication}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    return fullhtml;
  }

  getFMV(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.fmv}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getLoans(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.loans}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getLease(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.lease}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getFMVLease(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.fmvLease}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getTracLease(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.tracLease}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getUniqueStructure(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.uniqueStructure}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getQuasiLease(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.quasiLease}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getBenefitOfLeasing(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.benefitsOfLeasing}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getDiffLeaseAndLoan(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.diffLeaseLoan}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getLeasingGlossary(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.leasingGlossary}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = url.replace('${brand}', brand);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getTrainingLib(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.trainingLib}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getQuestions(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.questions}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getHowSellLeasing(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.howToSell}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getTroubleshoot(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.troubleshoot}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getDocLib(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.docLib}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getSampleDocLib(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.sampleDocLib}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getAdvantages(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.advantages}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getHelpPage(brand: string): Observable<any> {
    return this.appendLanguageMarker(`${BackEndUrl.help}?${this.dateTimeId.getDateTimeId}`).pipe(
      switchMap((url) => {
        const newUrl = this.generatePrivateLabelURL(brand, url);
        return this.httpClient.get(newUrl, { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  private appendLanguageMarker(url: string): Observable<string> {
    const lang = this.translation.getLocale().language;
    return this.localeStore.getStoredLocales().pipe(
      map((locale) => locale[lang]),
      map((item) => {
        if (item.default) {
          return url;
        }
        const split = url.split('.');
        return `${split[0]}-${item.lang}.${split[1]}`;
      })
    );
  }

  private generatePrivateLabelURL(brand: string, url: string): string {
    let newUrl = '';
    if (this.brandService?.programAttributes?.brandingTtype !== BrandingTypes.BMO && this.brandService?.selectedProgram) {
      newUrl = url.replace('${brand}', this.brandService?.selectedProgram?.vendorcode);
    } else {
      newUrl = url.replace('${brand}', brand);
    }
    return newUrl;
  }
}
