import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BmoLoadingComponent } from './bmo-loading.component';

@NgModule({
  declarations: [BmoLoadingComponent],
  imports: [CommonModule],
  exports: [BmoLoadingComponent],
})
export class BmoLoadingModule {}
