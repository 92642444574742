import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OutletHomeService } from '../../service-pool/outlet-home-service/outlet-home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  home$: Observable<string>;

  constructor(private outletHomeService: OutletHomeService) {}

  ngOnInit() {
    this.home$ = this.outletHomeService.getHomeContent();
  }
}
