import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private localeService: L10nTranslationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const defaultLocale = this.localeService.getLocale().language;
    let requestHeaders = {};
    if (isDevMode()) {
      requestHeaders = {
        locale: defaultLocale,
      };
    }
    return next.handle(
      request.clone({
        setHeaders: requestHeaders,
      })
    );
  }
}
