import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { OutletHomeService } from '../../service-pool/outlet-home-service/outlet-home.service';
import { CookieService } from 'ngx-cookie';
import { UIGroup } from 'src/app/model-old/ui-group';
import { Message } from 'src/app/model-old/message';

@Injectable()
export class ISAMLogoffInterceptor implements HttpInterceptor {
  constructor(
    private outletHomeService: OutletHomeService,
    private cookieService: CookieService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((redirectError: any) => {
        const brand = this.cookieService.get('bid') || '';
        const redirect = redirectError['url'].includes('portal/web') &&
        redirectError.error.text.includes('meta name="LOGINPAGE"');
        if (redirect) {
          if (brand) {
            window.location.href = redirectError['url'] + '#/?brand=' + brand;
          } else {
            window.location.href = redirectError['url'];
          }
        }
        return throwError(() => redirectError)
      })
      );
    }

}
