import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { CookieService } from 'ngx-cookie';
import { Company_Title } from 'src/app/model-old/company-title';
import { BrandingService } from 'src/app/service-pool/branding/branding.service';
import { GuardService } from '../../service-pool/business-services/guard-service/guard.service';
import { LoginService } from '../../service-pool/login-service/login.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  @Input() internalPage: boolean;

  @Input() unprotectedPage: boolean;

  brand: string;

  year: number;

  internalUser: boolean;

  loggedIn: boolean = false;

  isCanadian: boolean = false;

  get isClaas() {
    return Company_Title?.[this.brandService?.selectedProgram?.vendorcode] === 'CFS';
  }

  get vendorCodeString() {
    return Company_Title?.[this.brandService?.selectedProgram?.vendorcode] || 'BMO';
  }

  get showTopFooterLabel() {
    return this.vendorCodeString === 'CFS' || this.vendorCodeString === 'BMO';
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    protected _sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private guardService: GuardService,
    private loginService: LoginService,
    private brandService: BrandingService
  ) {}

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.brand = this.cookieService.get('bid');
    this.isCanadian = this.brand?.toUpperCase().includes('CA');
    this.internalUser = this.guardService.getInternalUser() === 'true';
    this.loginService.getLoggedIn().subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }

  safeHtml(html) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}
