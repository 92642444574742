import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie';
import { Company_Title } from 'src/app/model-old/company-title';
import { Page_Title } from 'src/app/model-old/page-title';
import { BrandingTypes } from '../branding/branding-types.enum';
import { BrandingService } from '../branding/branding.service';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  constructor(
    private titleService: Title,
    private brandingService: BrandingService,
    private cookieService: CookieService
  ) {}

  setTitle(url: string, internalPage: boolean) {
    let brand = this.cookieService.get('bid');
    if (!brand) {
      brand = `${this.getParam('brand')}`;
    }
    let titleVal = 'BMO';
    if (internalPage) {
      titleVal = 'BMO';
    } else if (this.brandingService?.programAttributes?.brandingTtype !== BrandingTypes.BMO) {
      titleVal = Company_Title[this.brandingService?.selectedProgram?.vendorcode] || 'BMO';
    }
    if (url) {
      if (url.includes('/salesrep')) {
        titleVal += ' - Salesrep';
      } else if (url.includes('/inter-admin')) {
        titleVal += ' - Internal Administration';
      } else if (Page_Title[url]) {
        titleVal = `${titleVal} - ${Page_Title[url]}`;
      }
    }
    this.titleService.setTitle(titleVal);
  }

  private getParam(name: string) {
    const results = new RegExp(`[\\?&]${name}=([^&#]*)`).exec(window.location.href);
    if (!results) {
      return '';
    }
    return results[1] || '';
  }
}
