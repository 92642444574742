import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ZipcodeService } from "../../../service-pool/business-services/zipcode-service/zipcode.service";
import { NGXLogger, NGXLoggerMonitor, NGXLogInterface } from 'ngx-logger';
import { UIGroup } from '../../../model-old/ui-group';
import { Validations } from '../../../sharedutility/validations';

@Component({
  selector: 'app-company-registration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.css']
})
export class CompanyRegistrationComponent implements OnInit {
  @Input('registrationForm') registrationForm: FormGroup;
  @Input('states') states;
  @Output('confirmEmailFlag') confirmEmailFlag = new EventEmitter();

  companyRegistrationForm: FormGroup;
  confirmEmailError: boolean;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private zipcodeService: ZipcodeService,
    private validations: Validations,
    private logger: NGXLogger
  ) {
    this.confirmEmailError = false;
  }

  ngOnInit() {
    this.logger.debug('---- states ----', this.states, UIGroup.PORTAL, 'company-registration.component.ts');
    //let EMAIL_REGEXP = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    this.companyRegistrationForm = this.formBuilder.group({
      companyName: ['', { validators: [Validators.required, Validators.min(1)] }],
      dbaTradeName: [''],
      locationType: ['hq'],
      address: ['', { validators: [Validators.required, Validators.min(1)] }],
      city: ['', { validators: [Validators.required, Validators.min(1)] }],
      state: ['', Validators.required],
      zipcode: ['', { validators: [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(5)] }],
      phoneNo: ['', { validators: [Validators.required, Validators.pattern(Validations.Phone_Number_Regex)] }],
      faxNo: ['', { validators: [Validators.pattern('[0-9()\-]*')] }],
      businessType: [''],
      lessThanTwo: ['N'],
      fName: ['', { validators: [Validators.required, Validators.min(1), Validators.pattern(Validations.NAME_REGEXP)] }],
      lName: ['', { validators: [Validators.required, Validators.min(1), Validators.pattern(Validations.NAME_REGEXP)] }],
      title: ['', { validators: [Validators.required, Validators.min(1), Validators.pattern(Validations.NAME_REGEXP)] }],
      email: ['', { validators: [Validators.required, Validators.email] }],
      confirmEmail: ['', { validators: [Validators.required, Validators.email] }],
    });
    //if (this.registrationForm) {
    this.logger.debug('---- outside: companyRegistrationForm ----', this.companyRegistrationForm, UIGroup.PORTAL, 'company-registration.component.ts');

    this.registrationForm.addControl('subRegistrationForm', this.companyRegistrationForm);
    this.logger.debug('---- Inside: companyRegistrationForm ----', this.companyRegistrationForm, UIGroup.PORTAL, 'company-registration.component.ts');
    //}
  }

  CheckConfirmEmail() {
    // debugger;
    if (this.companyRegistrationForm.controls['confirmEmail'].value !== '' && this.companyRegistrationForm.controls['email'].value !== '') {
      if (this.companyRegistrationForm.controls['confirmEmail'].value !== this.companyRegistrationForm.controls['email'].value) {
        this.confirmEmailError = true;
      } else {
        this.confirmEmailError = false;
      }
    }
    this.confirmEmailFlag.emit(this.confirmEmailError);
  }
  getZipCode(event) {
    let addrCtrl = this.companyRegistrationForm;
    if (typeof event === 'string' || event === null) {
      return;
    }
    if (event.target.value && addrCtrl.get('zipcode').valid) {
      this.zipcodeService.getZipCode(event.target.value, 'cityState').subscribe(address => {
        this.logger.debug('---- address ----', address, UIGroup.PORTAL, 'company-registration.component.ts');
        if (address == null) {
          addrCtrl.get('city').setValue('');
          addrCtrl.get('state').setValue('');
        } else {
          if (address instanceof Array) {
            return;
          } else if (typeof address === 'object') {
            addrCtrl.get('city').setValue(address['city']);
            addrCtrl.get('state').setValue(address['state']);
          } else {
            addrCtrl.get('city').setValue('');
            addrCtrl.get('state').setValue('');
          }
        }
      });
    } else {
      addrCtrl.get('city').setValue('');
      addrCtrl.get('state').setValue('');
    }
  }
  // confirmEmailValidation(companyRegistrationForm: FormGroup) {
  //   const email = companyRegistrationForm.controls['email'].value;
  //   const confirmEmail = companyRegistrationForm.controls['confirmEmail'].value;
  //   return email === confirmEmail ? null : {unMatched: true};
  // }
  updateFormat(val, source) {
    if (source === 'phoneNo') {
      let phone;
      phone = val.replace(/[()-]/g, '');
      this.companyRegistrationForm.controls['phoneNo'].setValue(phone);
    }
    if (source === 'faxNo') {
      let fax;
      fax = val.replace(/[()-]/g, '');
      this.companyRegistrationForm.controls['faxNo'].setValue(fax);
    }
  }
}
