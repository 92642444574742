import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Inject, Injectable, Injector } from '@angular/core';
import { BmoLoadingComponent } from './bmo-loading.component';

@Injectable({
  providedIn: 'root',
})
export class BmoLoadingService {
  private count = 0;

  private spinnerRef!: ComponentRef<BmoLoadingComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT) private dom: Document
  ) {}

  start() {
    if (this.count === 0) {
      this.appendLoadSpinnerComponent();
    }
    this.count += 1;
  }

  stop() {
    this.count -= 1;
    if (this.count === 0) {
      this.appRef.detachView(this.spinnerRef.hostView);
      this.spinnerRef.destroy();
    }
  }

  private appendLoadSpinnerComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BmoLoadingComponent);
    this.spinnerRef = componentFactory.create(this.injector);
    this.appRef.attachView(this.spinnerRef.hostView);
    const domElem = (this.spinnerRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.dom.querySelector('body')?.appendChild(domElem);
  }
}
