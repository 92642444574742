<ng-container *ngIf="internalUser && !internalPage && !showTopFooterLabel">
  <br />
  <br />
  <p style="text-align: center; bottom: 90px; position: absolute; left: 42vw">
    {{ 'PORTAL_FOOTER.' + vendorCodeString + '_TOP' | translate: locale.language }}
  </p>
</ng-container>
<footer class="footer">
  <div class="container" *ngIf="internalUser">
    {{ (internalPage ? 'PORTAL_LABEL.COPYRIGHT_INFO' : 'PORTAL_FOOTER.' + vendorCodeString + '_REG') | translate: locale.language : { year } }}
    <ng-container *ngIf="isClaas; else genericFooter">
      <a *ngIf="!internalPage" style="text-decoration: none" [href]="'#/?brand=' + brand" target="_blank" tabindex="30">
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.HOME' | translate: locale.language }}</span>
      </a>

      <a style="text-decoration: none" href="https://www.claasofamerica.com/sales-financing/cfs-usa/legal" target="_blank" tabindex="31">
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.PRIVACY' | translate: locale.language }}</span>
      </a>

      <a style="text-decoration: none" href="https://www.claasofamerica.com/sales-financing/cfs-usa/legal" target="_blank" tabindex="32">
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.LEGAL' | translate: locale.language }}</span>
      </a>

      <a style="text-decoration: none" href="https://www.claasofamerica.com/sales-financing/cfs-usa/legal" target="_blank" tabindex="33">
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.SECURITY' | translate: locale.language }}</span>
      </a>

      <a
        style="text-decoration: none"
        [href]="(internalPage ? '#/salesrep/terms' : '#/terms') + (unprotectedPage ? '?brand=' + brand : '')"
        target="_blank"
        tabindex="34"
      >
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.TERMS_CONDITION' | translate: locale.language }}</span>
      </a>

      <a
        style="text-decoration: none"
        [href]="(internalPage ? '#/salesrep/secure' : '#/secure') + (unprotectedPage ? '?brand=' + brand : '')"
        target="_blank"
        tabindex="32"
      >
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.SITE_SECURITY' | translate: locale.language }}</span>
      </a>
    </ng-container>

    <ng-template #genericFooter>
      <a *ngIf="!internalPage" style="text-decoration: none" [href]="'#/?brand=' + brand" target="_blank" tabindex="30">
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.HOME' | translate: locale.language }}</span>
      </a>

      <a style="text-decoration: none" href=" https://www.bmo.com/en-us/main/about-us/privacy/" target="_blank" tabindex="31">
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.PRIVACY' | translate: locale.language }}</span>
      </a>

      <a style="text-decoration: none" href=" https://www.bmo.com/en-us/main/about-us/legal/" target="_blank" tabindex="32">
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.LEGAL' | translate: locale.language }}</span>
      </a>

      <a style="text-decoration: none" href="https://www.bmo.com/en-us/main/personal/security-center/" target="_blank" tabindex="33">
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.SECURITY' | translate: locale.language }}</span>
      </a>

      <a
        style="text-decoration: none"
        [href]="(internalPage ? '#/salesrep/terms' : '#/terms') + (unprotectedPage ? '?brand=' + brand : '')"
        target="_blank"
        tabindex="34"
      >
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.TERMS_CONDITION' | translate: locale.language }}</span>
      </a>

      <a
        style="text-decoration: none"
        [href]="(internalPage ? '#/salesrep/secure' : '#/secure') + (unprotectedPage ? '?brand=' + brand : '')"
        target="_blank"
        tabindex="32"
      >
        |
        <span style="text-decoration: underline">{{ 'PORTAL_LABEL.SITE_SECURITY' | translate: locale.language }}</span>
      </a>
    </ng-template>
  </div>
</footer>
