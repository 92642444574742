export enum UIGroup {
  'PORTAL' = 'portal',
  'DMC_UI' = 'dmc-ui',
  'CA_UI' = 'ca-ui',
  'CA_IDEALEASE_UI' = 'ca-idealease-ui',
  'SALESREP_UI' = 'salesrep-ui',
  'PE_UI' = 'pe-ui',
  'PROFILE' = 'profile',
  'ADMIN_EXT' = 'admin-ui',
  'ADMIN_SALESREP' = 'inter-admin-ui',
  'REPORTS' = 'reports',
  'E_SALESREP' = 'emulate-salesrep-ui',
  'MANAGE_SALESREP' = 'manage-salesrep'
}

