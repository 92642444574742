// eslint-disable-next-line @typescript-eslint/naming-convention
export const Company_Title = {
  TRANSPORTATION: 'Transportation Finance',
  NFS: 'Leasing Source',
  'VFS-011': 'BMO',
  'VFS-020': 'CFS',
  'VFS-021': 'CFS',
  'VFS-031': 'DWFS',
  'VFS-032': 'DWFS',
  'VFS-036': 'DWFS',
  'VFS-040': 'BMO',
  'VFS-041': 'BMO',
  'VFS-048': 'BMO',
  'VFS-072': 'BMO',
  'VFS-089': 'BMO',
  'VFS-090': 'BMO',
  'VFS-091': 'MGF',
  'VFS-092': 'MGF',
  'VFS-093': 'BMO',
  'VFS-094': 'BMO',
  'VFS-098': 'BMO',
  'VFS-101': 'BMO',
  'VFS-102': 'DWFS',
  'VFS-120': 'TFS',
  'VFS-121': 'TFS',
  'VFS-123': 'BMO',
  'VFS-126': 'BMO',
  'VFS-127': 'BMO',
  'VFS-148': 'HPS',
};
