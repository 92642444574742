import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { LogoffDataService } from '../../data-services/logoff-service/logoff-data.service';

@Injectable()
export class LogoffService {
  brand: string;

  constructor(
    private logOffDataService: LogoffDataService,
    private cookieService: CookieService,
    private logger: NGXLogger
  ) {}

  logOff(status?) {
    this.brand = this.cookieService.get('bid');

    if (status && status === 401) {
      this.clearClientSideCookie();
      this.callPKMSLogout();
    } else {
      this.logOffDataService.clearBackendTokenAndCookies().subscribe(
        (resp) => {
          this.logger.debug("Response from 'savelogoutdetails'", resp);
          this.clearClientSideCookie();
          this.logOffDataService.userLogOff().subscribe(
            (resp_1) => {
              this.logger.debug("Response from 'pkmslogout'", resp_1);
              this.callPKMSLogout();
            },
            (error_1) => {
              this.logger.error("Error from 'pkmslogout'", error_1);
              this.callPKMSLogout();
            }
          );
        },
        (error) => {
          this.logger.error("Error from 'savelogoutdetails'", error);
          this.clearClientSideCookie();
          this.logOffDataService.userLogOff().subscribe(
            () => {
              this.callPKMSLogout();
            },
            () => {
              this.callPKMSLogout();
            }
          );
        }
      );
    }
  }

  clearISAMSession() {
    this.brand = this.cookieService.get('bid');

    this.logOffDataService.userLogOff().subscribe(
      (resp_1) => {
        this.logger.debug("Response from 'pkmslogout'", resp_1);
        this.callPKMSLogout();
      },
      (error_1) => {
        this.logger.error("Error from 'pkmslogout'", error_1);
        this.callPKMSLogout();
      }
    );
  }

  clearClientSideCookie() {
    this.cookieService.deleteAll('/');
  }

  clearPMKSLogoutCookie() {
    this.logOffDataService.userLogOff();
  }

  callPKMSLogout() {
    window.location.href = `/portal/web/#/?brand=${this.brand}`;
  }
}
