import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackEndUrl } from '../../sharedutility/backend-url';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loggedIn: BehaviorSubject<boolean>;

  private user: string;

  constructor(private httpClient: HttpClient) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  setLoggedInUserName(user: string) {
    this.user = user;
  }

  notifyLoggedIn() {
    this.loggedIn.next(true);
  }

  getLoggedIn() {
    return this.loggedIn.asObservable();
  }

  getUser() {
    return this.user;
  }

  acceptAgreement(viewDisclaimer: string, agreement: string) {
    return this.httpClient.get(`${BackEndUrl.acceptAgreement}/${viewDisclaimer}/${agreement}`);
  }

  userLogin(brand: string, loginDTO: HttpParams) {
    if (brand === 'NFS') {
      return this.httpClient.post(BackEndUrl.nfslogIn, loginDTO, { observe: 'response' });
    }
    if (brand === 'TRANSPORTATION') {
      return this.httpClient.post(BackEndUrl.tfslogIn, loginDTO, { observe: 'response' });
    }
    if (brand === 'NFS_CA') {
      return this.httpClient.post(BackEndUrl.nfs_ca_logIn, loginDTO, { observe: 'response' });
    }
    if (brand === 'TRANSPORTATION_CA') {
      return this.httpClient.post(BackEndUrl.tfs_ca_logIn, loginDTO, { observe: 'response' });
    }
    return this.httpClient.post(BackEndUrl.vef_logIn, loginDTO, { observe: 'response' });
  }

  redirectToLanding() {
    return this.httpClient.get(BackEndUrl.redirectLanding, { responseType: 'text', observe: 'response' });
  }

  sendUserNameEmailEntry(userEmailDTO: HttpParams) {
    return this.httpClient.post(BackEndUrl.verifyISAMUserNameEmail, userEmailDTO, { observe: 'response' });
  }

  sendOTPDeliveryChoice(otpGenUrl: string, otpDeliveryChoiceDTO: HttpParams) {
    return this.httpClient.post(otpGenUrl, otpDeliveryChoiceDTO, { observe: 'response' });
  }

  sendOTPVerifyCode(otpVerifyUrl: string, otpVerifyDTO: HttpParams) {
    return this.httpClient.post(otpVerifyUrl, otpVerifyDTO, { observe: 'response' });
  }

  sendOTPRegenerateCode(otpRegenUrl: string) {
    return this.httpClient.get(otpRegenUrl, { observe: 'response' });
  }

  sendUpdatePwd(updateDTO: HttpParams) {
    return this.httpClient.post(BackEndUrl.vefUpdatePassword, updateDTO, { observe: 'response' });
  }

  sendAPIUpdatePwd(updateDTO: { username: string; password: any }) {
    return this.httpClient.post(BackEndUrl.vefForgotPasswordAPIUpdate, updateDTO, { observe: 'response' });
  }
}
