<div style="margin: 0 auto">
  <div class="row" style="padding: 20px 0px 20px 15px;">
    <div class="form-check form-check-inline col">
      <a [href]="disclaimerDocUrl" target="_blank"
        (click)="updateLoginDisclaimerViewedFlag()">{{'PORTAL_LABEL.LOGIN_DISCLAIMER'| translate:locale.language }}</a>
      <input type="checkbox" class="form-check-input ml-10" id="loginDisclaimer" [attr.disabled]="true"
        [(ngModel)]="loginDisclaimerViewed">
      <span class="help-block" *ngIf="submitted && !loginDisclaimerViewed">
        <span class="required">
          {{'PORTAL_ERROR.ERR_MESG_DOC_MANDATORY'| translate:locale.language }}
        </span>
      </span>
    </div>
  </div>
  <p>{{'PORTAL_LABEL.FIRST_USER_MSG_1'| translate:locale.language }}</p>
  <p>{{'PORTAL_LABEL.FIRST_USER_MSG_2'| translate:locale.language }}</p>
  <button (click)="submit()" class="accept-btn">{{'PORTAL_LABEL.ACCEPT'| translate:locale.language }}</button>
  <button (click)="decline()" class="decline-btn ml-10">{{'PORTAL_LABEL.DECLINE'| translate:locale.language }}</button>
  <div *ngIf="errorMsg" class="required">
    {{errorMsg}}
  </div>
</div>
